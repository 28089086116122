<template>
    <div>
        <banner :banner-name="bannername"
                :background-img="$store.getters.getBackground('articles_list')"/>
        <v-row class="blog-container tag-details-container ">
            <v-col :key="item.id" v-for="item in current_data"
                   class="tag-details-item col-md-4 col-12 animate__animated animate__zoomIn">
                <v-card>
                    <router-link tag="div" :to="'/articles/'+item.id" class="tag-detail-img">
                        <v-img class="to-cover" width="100%" height="100%"
                               :src="item.articles_image_url"></v-img>
                    </router-link>
                    <router-link tag="div" :to="'/articles/'+item.id" class="tag-detail-info">
                        <div class="info-title">{{item.articles_title}}</div>
                        <div class="mt-1">
                            <span class="info-date"><v-icon>mdi-clock-time-eight-outline</v-icon><span class="ml-1">{{item.create_time}}</span></span>
                            <router-link tag="span" :to="'/categories/'+item.categorie.id" class="float-right info-tag">
                                <v-icon color="#767676">mdi-tag</v-icon>
                                {{ item.categorie.categorie_name }}
                            </router-link>
                        </div>
                    </router-link>
                    <div class="tag-wrapper">
                        <a :key="tag.id" v-for="tag in item.tags" :href="/tags/+tag.id"
                           class="tag-btn">{{tag.tag_name}}</a>

                    </div>
                </v-card>
            </v-col>
        </v-row>
        <div class="mr-5 ml-5">
            <v-pagination
                    v-show="this.pagingData.totalPages >1"
                    class="mt-5"
                    v-model="pagingData.pageNum"
                    :length="this.pagingData.totalPages"
                    @input="handleCurrentChange"
            ></v-pagination>
        </div>
    </div>

</template>

<script>

  import Banner from "@/components/banner/Banner";
  import {getArticlesListApi, getArticlesTagsApi, getArticlesCategoriesApi} from "@/network/interface";

  export default {
    name: "TagDetails",
    data() {
      return {
        data_name: "",
        data_type: "",
        data_id: '',
        current_data: [],
        pagingData: {
          currentPage: 1, // 当前页数
          pageNum: 1,
          pageSize: 6,
          totalPages: 0,
        }
      }
    },
    created() {
      /*判断是否是数字*/
      if (!this.isNumber(this.$route.params.id)) {
        this.$router.push('/')
      }
      const params = {pageNum: this.pagingData.pageNum, pageSize: this.pagingData.pageSize}
      /*获取当前id信息*/
      this.data_type = this.$route.name === 'tags_id' ? 'tag' : 'categorie'
      this.data_id = this.$route.params.id
      this.getCurrentPageName()
      this.getCurrentData(this.pagingData.pageNum)
    },
    computed: {
      bannername() {
        return this.data_type === "tag" ? "标签 - " + this.data_name : "分类 - " + this.data_name
      }
    },
    methods: {
      /*是否是数字*/
      isNumber(value) {
        const regPos = /^\d+?$/
        if (regPos.test(value)) {
          return true
        } else {
          return false
        }
      },
      getCurrentData(pageNum) {
        const params = {pageNum: pageNum, pageSize: this.pagingData.pageSize}
        params[this.data_type] = this.data_id
        getArticlesListApi(params).then(resp => {
          this.current_data = resp.data.result
          this.pagingData.totalPages = resp.data.totalPages
        })
      },
      handleCurrentChange(number) {
        if (this.pagingData.currentPage !== number) {
          this.pagingData.currentPage = number
          this.getCurrentData(number)
        }
      },
      getCurrentPageName() {
        const apis = {tag: getArticlesTagsApi, categorie: getArticlesCategoriesApi}
        apis[this.data_type]({id: this.data_id}).then(resp => {
          this.data_name = resp.data.tag_name || resp.data.categorie_name
        })

      },
    },
    components: {
      Banner
    }
  }
</script>

<style scoped>


    .tag-details-container {
        max-width: 1200px;
        margin: 485px auto 0 auto;
        padding: 0;
    }

    .tag-details-item {
        padding: 7.5px !important;

    }

    .tag-detail-img {
        height: 220px;
        overflow: hidden;
    }

    .tag-detail-img:hover .to-cover {
        transform: scale(1.2);
    }

    .tag-detail-img .to-cover {

        transition: 1s all;
    }

    .tag-detail-info {
        padding: 15px 15px 12px 18px;
        color: #4c4948;;
        border-bottom: 2px solid #e0e0e0;
    }

    .info-date {
        font-size: 0.9rem;
    }

    .info-date .v-icon, .info-tag .v-icon {
        font-size: 1.1rem;
    }

    .float-right {
        float: right;
    }


    .tag-wrapper {
        padding: 10px 15px 10px 18px;
    }


    .tag-btn {
        display: inline-block;
        padding: 0 12px;
        line-height: 22px;
        height: 22px;
        color: #eeeeee !important;
        border-radius: 10px;
        font-size: 0.78rem;
        opacity: 0.6;
        background: linear-gradient(90deg, #bf4643 0, #6c9d8f);;
        margin-right: 5px;
    }


    .theme--dark .info-title,
    .theme--dark .info-date,
    .theme--dark .info-tag,
    .theme--dark .info-tag .v-icon,
    .theme--dark .tag-btn {
        color: #eeeeee !important;
    }

    @media screen and (max-width: 960px) {
        .tag-details-container {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    @media screen and (max-width: 750 px) {
        .tag-details-container {
            margin-top: 300px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

</style>
